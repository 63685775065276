import React from 'react'
import { PageProps, graphql } from 'gatsby'

import Layout from 'components/layout/layout'

import Header from 'components/sections/main-header'

import Newsletter from 'components/sections/newsletter'

import type { CzymJestAurecPageQuery } from 'types/graphql'
import AboutAurec from 'components/sections/about-aurec'
import Future from 'components/sections/future'
import PlacesInAurec from 'components/sections/places-in-aurec'
import WhatYouCanDo from 'components/sections/what-you-can-do'
// import SlideGallery from 'components/sections/slide-gallery'
import Seo from 'components/layout/seo'
import useBreakpoint from 'hooks/useBreakpoint'
import AskAboutFlats from 'components/ask-about-flats'

const CzymJestAurec: React.FC<PageProps<CzymJestAurecPageQuery>> = ({
  data,
}) => {
  const PAGE = data?.wpPage?.czymJestAurec

  const HERO_IMAGE = {
    src: PAGE?.aboutAurecHeroImg?.localFile?.childImageSharp?.gatsbyImageData!,
    alt: PAGE?.aboutAurecAboutImg?.altText!,
  }

  const ATTRACTIONS = PAGE?.aboutAurecAttraction?.map((attraction) => ({
    title: attraction?.aboutAurecAttractionName!,
    image: {
      src: attraction?.aboutAurecAttractionImg?.localFile?.childImageSharp
        ?.gatsbyImageData!,
      alt: attraction?.aboutAurecAttractionImg?.altText,
    },
    description: attraction?.aboutAurecAttractionDescription!,
  }))

  const PLACES = PAGE?.aboutAurecPlaces?.map((place) => ({
    name: place?.aboutAurecPlacesTitle!,
    location: place?.aboutAurecPlacesInvestment!,
    image: {
      src: place?.aboutAurecPlacesImg?.localFile?.childImageSharp
        ?.gatsbyImageData!,
      alt: place?.aboutAurecPlacesImg?.altText!,
    },
    icon: place?.aboutAurecPlacesIcon!,
  }))

  const { lg } = useBreakpoint()

  return (
    <Layout>
      <Seo
        title={data?.wpPage?.seo?.title!}
        description={data?.wpPage?.seo?.metaDesc!}
        ogTitle={data?.wpPage?.seo?.opengraphTitle!}
        ogDescription={data?.wpPage?.seo?.opengraphDescription!}
        ogImage={data?.wpPage?.seo?.opengraphImage?.sourceUrl!}
      />
      <AskAboutFlats />
      <Header title={PAGE?.aboutAurecHeroTitle!} image={HERO_IMAGE} />
      <AboutAurec
        image={{
          src: PAGE?.aboutAurecAboutImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE?.aboutAurecAboutImg?.altText!,
        }}
        title={PAGE?.aboutAurecAboutTitle!}
        description={PAGE?.aboutAurecAboutText!}
      />
      <Future
        image={{
          src: PAGE?.aboutAurecFutureImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE?.aboutAurecFutureImg?.altText!,
        }}
        title={PAGE?.aboutAurecFutureTitle!}
        description={PAGE?.aboutAurecFutureDescription!}
      />
      <PlacesInAurec places={PLACES} />

      <WhatYouCanDo attractions={ATTRACTIONS} />
      <Newsletter withImage womanImage />

      {/* temporary fix */}
      {/* {lg && <div style={{ marginBottom: '200px' }} />} */}

      {/* <SlideGallery /> */}
    </Layout>
  )
}

export default CzymJestAurec

export const query = graphql`
  query CzymJestAurecPage {
    wpPage(slug: { regex: "/czym-jest-aurec/" }) {
      id
      czymJestAurec {
        aboutAurecAboutText
        aboutAurecAboutTitle
        aboutAurecFutureDescription
        aboutAurecFutureTitle
        aboutAurecHeroTitle
        aboutAurecAboutImg {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
            }
          }
          altText
        }
        aboutAurecFutureImg {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
            }
          }
          altText
        }
        aboutAurecHeroImg {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
            }
          }
          altText
        }
        aboutAurecAttraction {
          aboutAurecAttractionName
          aboutAurecAttractionDescription
          aboutAurecAttractionImg {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
              }
            }
            altText
          }
        }
        aboutAurecPlaces {
          aboutAurecPlacesIcon
          aboutAurecPlacesInvestment
          aboutAurecPlacesTitle
          aboutAurecPlacesImg {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
              }
            }
            altText
          }
        }
      }
      seo {
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
`
