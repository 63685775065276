import React from 'react'
import styled from 'styled-components'

import media from 'styles/media'

import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

type FutureProps = {
  image: {
    src: IGatsbyImageData
    alt: string
  }
  title: string
  description: string
}

const StyledWrapper = styled.section`
  margin-top: 55px;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 85px;
  padding-bottom: 90px;

  ${media.xl.min} {
    padding-top: 120px;
    padding-bottom: 220px;
  }

  background: transparent linear-gradient(163deg, #f4f9f300 0%, #f4f9f3 100%) 0%
    0% no-repeat padding-box;
`

const StyledBackgroundImage = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 100%;

  & > img {
    object-fit: cover;
    object-position: 0% 0%;
    /* font-family: 'object-fit: cover !important; object-position: 0% 0% !important;'; // needed for IE9+ polyfill */
  }
`

const StyledText = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.colors.green};
`

const StyledTitle = styled.p`
  margin-top: 22px;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
`

const StyledDescription = styled.p`
  margin-top: 65px;
  text-align: center;

  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  line-height: 2.5;
`

const Future: React.FC<FutureProps> = ({ image, title, description }) => {
  return (
    <StyledWrapper>
      <StyledBackgroundImage image={image.src} alt={image.alt} />
      <StyledText>Aurec Active</StyledText>
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{description}</StyledDescription>
    </StyledWrapper>
  )
}

export default Future
