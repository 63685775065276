import React from 'react'
import styled from 'styled-components'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

import AurecPlaces from 'components/sections/aurec-places'
import AurecPlaceCard from 'components/aurec-place-card'

import { AurecPlaceCardProps } from 'components/aurec-place-card'

const StyledWrapper = styled.div`
  margin-top: 70px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
`

const StyledText = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.colors.green};
`

const StyledTitle = styled.p`
  margin-top: 20px;
  text-align: center;
  font-size: 32px;
  font-weight: bold;

  ${media.xl.min} {
    font-size: 42px;
  }
`

const StyledPlacesWrapper = styled.div`
  padding: 0 20px;
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 40px;
  column-gap: 40px;
  justify-content: center;
  position: relative;

  ${media.lg.min} {
    padding: 0 50px;
  }
`

type PlacesInAurecProps = {
  places: Array<AurecPlaceCardProps>
}

const PlacesInAurec: React.FC<PlacesInAurecProps> = ({ places }) => {
  const { lg } = useBreakpoint()

  return (
    <StyledWrapper>
      <StyledText>Aurec Active</StyledText>
      <StyledTitle>Miejsca Aurec Active</StyledTitle>
      {lg ? (
        <StyledPlacesWrapper>
          {places.map((el) => (
            <AurecPlaceCard
              image={el.image}
              name={el.name}
              location={el.location}
              icon={el.icon}
              simpleDot
            />
          ))}
        </StyledPlacesWrapper>
      ) : (
        <AurecPlaces places={places} />
      )}
    </StyledWrapper>
  )
}

export default PlacesInAurec
